<template>
  <div class="col-md-12">
    <b-card
      header-bg-variant="primary"
      header-text-variant="white"
      bg-variant="default"
      align="center"
      style="filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.15)); border-radius: 10px;"
    >
      <div class="row d-flex justify-content-end" style="margin-bottom: 20px">
        <button
          type="button"
          class="hidden-button"
          @click="refreshData"
          id="refreshButton"
          >
          <i class="fas fa-sync-alt"></i>
        </button>
        <button
          class="btn btn-tambah-data"
          @click.prevent.stop="showModalAdd = true"
        >
          <i class="fa fa-plus-circle" style="color: #ffffff;"></i>Tambah Data
        </button>
      </div>
      <DxDataGrid
        :ref="dataGridRefKey"
        :data-source="dataSource"
        key-expr="id"
        :show-borders="true"
        :word-wrap-enabled="true"
        :column-auto-width="true"
        :remote-operations="true"
        :column-hiding-enabled="true"
      >
        <DxScrolling
          :use-native="true"
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          show-scrollbar="onHover"
        />
        <DxFilterRow :visible="true" />
        <DxColumn
          data-field="first_name"
          caption="Nama Depan"
          :filter-operations="['contains']"
        />
        <DxColumn
          data-field="last_name"
          caption="Nama Akhir"
          :filter-operations="['contains']"
        />
        <DxColumn
          data-field="email"
          caption="Email"
          :filter-operations="['contains']"
        />
        <DxColumn
          data-field="direktorat.name"
          caption="Direktorat"
          :allow-sorting="false"
          :allow-filtering="false"
        />
        <DxColumn
          data-field="role.name"
          caption="Role"
          :allow-sorting="false"
          :allow-filtering="false"
        />
        <DxColumn
          data-field="nip"
          caption="NIP"
          :filter-operations="['contains']"
        />

        <DxColumn :width="100" type="buttons" caption="Aksi">
          <DxButton icon="fa fa-trash" hint="Hapus" :onClick="iconDelete" />
          <DxButton icon="fa fa-edit" hint="Edit" :onClick="iconEdit" />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :show-page-size-selector="showPageSizeSelector"
          :allowed-page-sizes="pageSizes"
        />
      </DxDataGrid>
    </b-card>
    <!--   Modal Tambah Direktorat  -->

    <b-modal
      v-model="showModalAdd"
      hide-footer
      centered
      scrollable
      size="lg"
      id="modal-tambah-direktorat"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Tambah User</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="example-input-group-1" label-for="example-input-1">
          <template v-slot:label>
            Nama Depan
          </template>
          <b-form-input
            id="example-input-1"
            name="example-input-1"
            placeholder="Nama"
            v-model="$v.form.first_name.$model"
            :state="validateState('first_name')"
            aria-describedby="input-1-live-feedback"
            required
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback"
            >Nama depan harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-3"
          label-for="input-last_name"
          label="Nama Belakang"
        >
          <b-form-input
            id="input-last_name"
            name="input-last_name"
            placeholder="Nama Belakang"
            v-model="form.last_name"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-email" label-for="input-email">
          <template v-slot:label>
            Email
          </template>
          <b-form-input
            id="input-email"
            name="input-email"
            placeholder="Email"
            v-model="$v.form.email.$model"
            @blur="$v.form.email.$touch()"
            :state="validateState('email')"
            aria-describedby="input-email-live-feedback"
            type="email"
            autocapitalize="none"
            :formatter="lowercase"
            required
          ></b-form-input>
          <div class="error invalid-feedback" v-if="!$v.form.email.required">
            Email harus diisi.
          </div>
          <div class="error invalid-feedback" v-if="!$v.form.email.email">
            Email yang diinput tidak valid.
          </div>
        </b-form-group>

        <b-form-group id="input-group-password" label-for="input-password">
          <template v-slot:label>
            Kata Sandi
          </template>
          <b-form-input
            id="input-password"
            name="input-password"
            placeholder="Kata Sandi"
            v-model="$v.form.password.$model"
            @blur="$v.form.password.$touch()"
            :state="validateState('password')"
            aria-describedby="input-password-live-feedback"
            type="password"
            required
          ></b-form-input>
          <div class="error invalid-feedback" v-if="!$v.form.password.required">
            Kata Sandi harus diisi.
          </div>
          <div class="error invalid-feedback" v-if="!$v.form.password.minLength">
            Kata Sandi harus memiliki minimal 8 karakter.
          </div>
          <div 
            class="error invalid-feedback" 
            v-if="!($v.form.password.containsUppercase && $v.form.password.containsLowercase)"
          >
            Kata Sandi harus mengandung huruf besar dan kecil.
          </div>
          <div class="error invalid-feedback" v-if="!$v.form.password.containsNumber">
            Kata Sandi harus mengandung angka.
          </div>
          <div class="error invalid-feedback" v-if="!$v.form.password.containsSpecial">
            Kata Sandi harus mengandung karakter khusus.
          </div>
        </b-form-group>

        <b-form-group id="input-group-password_confirmation" label-for="input-password_confirmation">
          <template v-slot:label>
            Kata Sandi Konfirmasi
          </template>
          <b-form-input
            id="input-password_confirmation"
            name="input-password_confirmation"
            placeholder="Ketik ulang Kata Sandi"
            v-model="$v.form.password_confirmation.$model"
            @blur="$v.form.password_confirmation.$touch()"
            :state="validateState('password_confirmation')"
            aria-describedby="input-password_confirmation-live-feedback"
            type="password"
          ></b-form-input>
          <div class="error invalid-feedback">
            Kata Sandi dan Kata Sandi Konfirmasi tidak sama.
          </div>
        </b-form-group>

        <b-form-group
          id="example-input-group-direktorat"
          label-for="select-direktorat"
        >
          <template v-slot:label>
            Direktorat
          </template>
          <b-form-select
            id="select-direktorat"
            v-model="form.direktorat"
            :options="direktorats"
            aria-describedby="input-direktorat-live-feedback"
          >
            <template #first>
              <b-form-select-option :value="null"
                >-- Pilih Salah Satu --</b-form-select-option
              >
            </template>
          </b-form-select>

          <b-form-invalid-feedback id="input-direktorat-live-feedback"
            >Direktorat harus dipilih.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-status"
          label-for="select-status"
          label="Status"
        >
          <b-form-select
            id="select-status"
            v-model="form.status"
            :options="statuses"
            disabled
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Pilih Salah Satu --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group id="example-input-group-role" label-for="select-role">
          <template v-slot:label>
            Role
          </template>
          <b-form-select
            id="select-role"
            v-model="form.role"
            :options="roles"
            :state="validateState('role')"
            aria-describedby="input-role-live-feedback"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Pilih Salah Satu --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group id="nik-input-group" label="NIK" label-for="nik-input">
          <b-form-input
            id="nik-input"
            name="nik-input"
            placeholder="NIK"
            v-model="form.nik"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="nip-input-group" label-for="nip-input">
          <template v-slot:label>
            NIP
          </template>
          <b-form-input
            id="nip-input"
            name="nip-input"
            placeholder="NIP"
            v-model="form.nip"
            :state="validateState('nip')"
            aria-describedby="input-nip-live-feedback"
            required
          ></b-form-input>

          <b-form-invalid-feedback id="input-nip-live-feedback"
            >NIP harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="no_telp-input-group"
          label="No. Telp."
          label-for="no_telp-input"
        >
          <b-form-input
            id="no_telp-input"
            name="no_telp-input"
            placeholder="No. Telp."
            v-model="form.no_telp"
          ></b-form-input>
        </b-form-group>

        <hr />
        <div class="d-flex justify-content-center">
          <button class="btn btn-delete mr-8" @click="resetForm()">
            Kembali
          </button>
          <button type="submit" class="btn btn-save">Simpan</button>
        </div>
      </b-form>
    </b-modal>

    <!--   Modal Edit Direktorat   -->

    <b-modal
      v-model="showModalEdit"
      hide-footer
      scrollable
      centered
      size="lg"
      id="modal-edit-user"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Edit Direktorat</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmitEdit">
        <b-form-group id="first_name-group" label-for="first_name">
          <template v-slot:label>
            Nama Depan<span class="text-danger">*</span>
          </template>
          <b-form-input
            id="first_name"
            name="first_name"
            placeholder="Nama Depan"
            v-model="$v.formEdit.first_name.$model"
            :state="validateStateFormEdit('first_name')"
            aria-describedby="first_name-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="first_name-feedback"
            >Nama Depan harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="code-input-group"
          label="Nama Belakang"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            name="last_name"
            placeholder="Nama Belakang"
            v-model="formEdit.last_name"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="email-group" label-for="email">
          <template v-slot:label>
            Email<span class="text-danger">*</span>
          </template>
          <b-form-input
            id="email"
            name="email"
            placeholder="Email"
            v-model="$v.formEdit.email.$model"
            :state="validateStateFormEdit('email')"
            aria-describedby="email-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="email-feedback"
            >Email harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="edit-directorate" label-for="select-directorate-edit">
          <template v-slot:label>
            Direktorat
          </template>
          <b-form-select
            id="select-directorate-edit"
            v-model="formEdit.direktorat"
            :options="direktorats"
            aria-describedby="directorate-feedback"
          >
            <template #first>
              <b-form-select-option :value="null"
                >-- Pilih Salah Satu --</b-form-select-option
              >
            </template>
          </b-form-select>

          <b-form-invalid-feedback id="directorate-feedback"
            >Direktorat harus dipilih.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="status-group" label-for="is_active">
          <template v-slot:label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="select-status"
            v-model="formEdit.status"
            :options="statuses"
            :state="validateStateFormEdit('status')"
            aria-describedby="status-feedback"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Pilih Salah Satu --</b-form-select-option
              >
            </template>
          </b-form-select>

          <b-form-invalid-feedback id="status-feedback"
            >Status harus dipilih.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="role-group" label-for="role">
          <template v-slot:label>
            Role <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="role"
            v-model="formEdit.role"
            :options="roles"
            :state="validateStateFormEdit('role')"
            aria-describedby="role-feedback"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Pilih Salah Satu --</b-form-select-option
              >
            </template>

            <b-form-invalid-feedback id="role-feedback"
              >Role harus dipilih.
            </b-form-invalid-feedback>
          </b-form-select>
        </b-form-group>

        <b-form-group id="nik-group" label="NIK" label-for="nik">
          <b-form-input
            id="nik"
            name="nik"
            placeholder="NIK"
            v-model="formEdit.nik"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="nip-group" label-for="nip">
          <template v-slot:label>
            NIP <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="nip"
            name="nip"
            placeholder="NIP"
            v-model="formEdit.nip"
            :state="validateStateFormEdit('nip')"
            aria-describedby="nip-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="nip-feedback"
            >NIP harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="no_telp-group"
          label="No. Telepon"
          label-for="no_telp"
        >
          <b-form-input
            id="no_telp"
            name="no_telp"
            placeholder="No. telepon"
            v-model="formEdit.no_telp"
          ></b-form-input>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-delete mr-7" @click="resetFormEdit()">
            Batal
          </button>
          <button
            type="submit"
            class="btn btn-save"
            :disabled="$v.formEdit.$invalid"
          >
            Simpan
          </button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxScrolling
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { handleErrors, isNotEmpty } from "@/core/appUtil/util";
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import "whatwg-fetch";
import Swal from "sweetalert2";

let token = localStorage.getItem("token");

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists
    // console.log(loadOptions["filter"]);

    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0
    ) {
      let filters = loadOptions["filter"];
      let isMultiple = Array.isArray(filters[0]);
      if (isMultiple) {
        filters.forEach(el => {
          if (Array.isArray(el)) {
            params += "&" + el[0] + "=" + el[2];
          }
        });
      } else {
        params += "&" + filters[0] + "=" + filters[2];
      }
    }

    return fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/users${params}`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.data === null) {
          setTimeout(() => {
            document.querySelector("#refreshButton").click();
          }, 500);

        } else {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount
          };
        }
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});

const dataGridRefKey = "my-data-grid";

const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  mixins: [validationMixin],
  name: "User",
  components: {
    DxDataGrid,
    DxPaging,
    DxPager,
    DxColumn,
    DxButton,
    DxFilterRow,
    DxScrolling
  },
  data() {
    return {
      showModalAdd: false,
      showModalEdit: false,
      isValid: false,
      form: {
        first_name: "",
        last_name: "",
        direktorat: null,
        email: "",
        status: true,
        nik: "",
        nip: "",
        no_telp: "",
        role: null,
        password: "",
        password_confirmation: ""
      },
      formEdit: {
        id: null,
        first_name: "",
        last_name: "",
        direktorat: null,
        email: "",
        status: null,
        nik: "",
        nip: "",
        no_telp: "",
        role: null
      },
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      dataModal: {},
      direktorats: [],
      roles: [],
      statuses: [
        {
          value: "true",
          text: "Aktif"
        },
        { value: "false", text: "Tidak Aktif" }
      ]
    };
  },
  validations: {
    form: {
      first_name: {
        required
      },
      // direktorat: {
      //   required
      // },
      email: {
        required,
        email
      },
      role: {
        required
      },
      nip: {
        required
      },
      password: {
        required, 
        minLength: minLength(8),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function(value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function(value) {
          return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);// eslint-disable-line
        }
      },
      password_confirmation: {
        required, 
        sameAsPassword: sameAs('password')
      }
    },
    formEdit: {
      first_name: {
        required
      },
      // direktorat: {
      //   required
      // },
      email: {
        required,
        email
      },
      status: {
        required
      },
      role: {
        required
      },
      nip: {
        required
      }
    }
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    })
    .then(() => { // Load direktorat and role after token is loaded
      this.getRefDirektorat()
      this.getRefRole()
    });

  },
  computed: {
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    getRefRole(attempt = 0) {
      attempt++;

      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/roles?take=100`), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`
        }
      })
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getRefRole(attempt);

          } else {
            const detailMenu = items.data;
            let selectedOptions;
            selectedOptions = [];
  
            if (detailMenu) {
              detailMenu.map(i => {
                return selectedOptions.push({
                  text: i.name,
                  value: i.id
                });
              });
            }
  
            this.roles = selectedOptions;
          }
        });
    },
    getRefDirektorat(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/master/direktorat?take=100`),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getRefDirektorat(attempt);

          } else {
            const detailMenu = items.data;
            let selectedOptions;
            selectedOptions = [];
  
            if (detailMenu) {
              detailMenu.map(i => {
                return selectedOptions.push({
                  text: i.name,
                  value: i.id
                });
              });
            }
  
            this.direktorats = selectedOptions;
          }
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        id: null,
        first_name: "",
        last_name: "",
        direktorat: null,
        email: "",
        status: true,
        nik: "",
        nip: "",
        no_telp: "",
        role: null,
        password: "",
        password_confirmation: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-tambah-direktorat");
      });
    },
    resetFormEdit() {
      // this.formEdit = {
      //   name: null,
      //   code: null
      // };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-user");
      });
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }

      let id = this.formEdit.id;
      let data = {
        id: null,
        first_name: this.formEdit.first_name,
        last_name: this.formEdit.last_name,
        email: this.formEdit.email,
        direktorat_id: this.formEdit.direktorat,
        is_active: JSON.parse(this.formEdit.status),
        nik: this.formEdit.nik,
        nip: this.formEdit.nip,
        no_telp: this.formEdit.no_telp,
        role_id: this.formEdit.role
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/users/${id}`),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.success,
                showConfirmButton: false,
                timer: 1500
              });
              this.resetFormEdit();
              this.refreshData();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    setDataModal(data) {
      this.dataModal(data);
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let data = {
        id: null,
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        direktorat_id: this.form.direktorat,
        is_active: JSON.parse(this.form.status),
        nik: this.form.nik,
        nip: this.form.nip,
        no_telp: this.form.no_telp,
        role_id: this.form.role,
        code: this.form.password,
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/users`), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token
            },
            body: JSON.stringify(data)
          })
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.refreshData();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data yang ditambah sudah ada",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    refreshData() {
      this.dataGrid.refresh();
    },
    iconDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/users/${id}`),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.refreshData();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    iconEdit(e) {
      const data = e.row.data;
      this.formEdit.first_name = data.first_name;
      this.formEdit.last_name = data.last_name;
      this.formEdit.email = data.email;
      this.formEdit.nip = data.nip;
      this.formEdit.nik = data.nik;
      this.formEdit.no_telp = data.no_telp;
      this.formEdit.status = data.is_active;
      this.formEdit.role = data.role_id;
      this.formEdit.direktorat = data.direktorat_id;
      this.formEdit.id = data.id;
      this.setDataModal = data;
      this.$root.$emit("bv::show::modal", "modal-edit-user", e.row.data);
    },
    lowercase(str) {
      return str.toLowerCase();
    }
  }
};
</script>
